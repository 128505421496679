import React from "react";
import { graphql } from "gatsby";
import { ShopLayout } from "../components/ShopLayout";

const ShopPage = ({ data, pageContext }) => {
  const { edges } = data.allMarkdownRemark;
  const products = edges.map(({ node }) => ({
    id: node.id,
    title: node.frontmatter.title,
    src: `/shop/products/${node.frontmatter.title}`,
    image: node.frontmatter.featuredImage,
    price: node.frontmatter.price,
    slug: node.fields.slug
  }));
  return (
    <ShopLayout
      products={products}
      pageContext={pageContext}
      page="shop"
      title={"All products"}
    />
  );
};

export default ShopPage;

export const ShopPageQuery = graphql`
  query ShopPage($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "product-page" } } }
      sort: { order: DESC, fields: [frontmatter___title] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            price
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 300, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
