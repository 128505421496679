import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import {ProductList} from "./ProductList";

export const CategoriesList = () => {
    const { allMarkdownRemark } = useStaticQuery(graphql`
        query CategoryQuery {
            allMarkdownRemark(limit: 1000) {
                group(field: frontmatter___category) {
                    fieldValue
                }
            }
        }
    `);
    const cats = allMarkdownRemark.group;

    return <ProductList list={cats} link='bespoke-orders/category'/>;
};
