import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import {ProductList} from "./ProductList";

export const TagsList = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query TagQuery {
      allMarkdownRemark(limit: 1000) {
        group(field: frontmatter___tags) {
          fieldValue
        }
      }
    }
  `);
  const cats = allMarkdownRemark.group;

  return <ProductList list={cats} link='shop/tags'/>;
};
