import styled from "styled-components";
import {Link} from "gatsby";
import {colors} from "../styles/variables";
import React from "react";

const PaginationContainer = styled.div`
  display: flex;
  margin: 0 auto;
  width: max-content;
`;

const PaginationLink = styled(Link)`
  padding: 5px;
  border: solid 1px ${colors.gold};
  transition: 0.4s;
  font-size: 14px;
  &.active,
  &:hover {
    background: ${colors.gold};
    color: white;
  }
`;

export const Pagination = ({ pageContext, pageUrl }) => {
    const { currentPage, numPages } = pageContext;
    if (numPages === 1) {
        return null;
    }
    let pages =
        currentPage === 1
            ? [1, 2, 3]
            : [currentPage - 1, currentPage, currentPage + 1];
    if(numPages < 3) {
        pages = [1, 2];
    }
    return (
        <PaginationContainer>
            {currentPage !== 1 && (
                <PaginationLink to={currentPage - 1 === 1 ? pageUrl.toString() : `${pageUrl}/${currentPage - 1}`}>Prev</PaginationLink>
            )}
            {pages.map(page => (
                <PaginationLink
                    className={currentPage === page? "active" : ""}
                    key={page}
                    to={page === 1 ? pageUrl.toString() : `${pageUrl}/${page}`}
                >
                    {page}
                </PaginationLink>
            ))}
            {currentPage !== numPages && (
                <PaginationLink to={`${pageUrl}/${currentPage + 1}`}>Next</PaginationLink>
            )}
        </PaginationContainer>
    );
};
