import { Link } from "gatsby";
import { colors } from "../styles/variables";
import React from "react";
import { kebabCase, capitalize } from "lodash";
import styled from "styled-components";

const ListPanel = styled.div`
  background: ${colors.offWhite};
  height: fit-content;
  grid-column-start: 1;
  grid-column-end: -1;
  .categories {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  @media (min-width: 850px) {
    max-width: 200px;
    grid-column-start: 4;
    grid-row-start: 1;
    grid-row-end: 4;
    .categories {
      flex-direction: column;
    }
  }
`;

const ListItem = styled(Link)`
  padding: 5px 10px;
  p {
    color: ${colors.accent};
    transition: color 0.45s;
  }
  p:hover,
  &.active p {
    color: ${colors.gold};
  }
`;

export const ProductList = ({ list, link}) => (
    <ListPanel className="column">
        <h5 className="title is-6" style={{ marginBottom: 10 }}>
            Categories:
        </h5>
        <div className="categories">
            <ListItem to={link.split('/')[0]} activeClassName="active">
                <p>All</p>
            </ListItem>
            {list.map(({ fieldValue }, index) => (
                <ListItem
                    to={`${link}/${kebabCase(fieldValue)}`}
                    activeClassName="active"
                    key={index}
                >
                    <p>{capitalize(fieldValue)}</p>
                </ListItem>
            ))}
        </div>
    </ListPanel>
);
