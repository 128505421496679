import React from "react";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import styled from "styled-components";
import { colors } from "../styles/variables";

const Card = styled.div`
  background: ${colors.offWhite};
  height: 100%;
  display: flex;
  flex-direction: column;
  div {
    padding: 10px;
  }
  h4 {
    font-size: 15px;
    color: ${colors.black};
    margin: 0 0 0.5em;
    font-weight: 700;
    line-height: 1.35em;
  }
  .gatsby-image-wrapper:before {
    content: "";
    background: ${colors.accent};
    opacity: 0.2;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transform: translateY(100%);
    transition: 0.4s;
  }
  .gatsby-image-wrapper:after {
    content: "⌕";
    font-size: 55px;
    line-height: 50px;
    padding: 3px 10px;
    background: ${colors.gold};
    opacity: 1;
    position: absolute;
    top: calc(50% - 75px);
    left: calc(50% - 35px);
    z-index: 2;
    transform: translateY(100%);
    transition: 0.4s;
    border-radius: 5px;
    opacity: 0;
    transition: 0.4s;
  }
  &:hover .gatsby-image-wrapper:after {
    opacity: 1;
  }
  &:hover .gatsby-image-wrapper:before {
    transform: translateY(0);
  }
`;

export const ProductItem = ({ title, image, price }) => {
  return (
    <Card>
      <PreviewCompatibleImage imageInfo={{ image }} />
      <div>
        <h4>{title}</h4>
        {price && <p>£{price}</p>}
      </div>
    </Card>
  );
};
