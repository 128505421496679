import { Link } from "gatsby";
import { ProductItem } from "./ProductItem";
import Layout from "./Layout";
import React from "react";
import { Pagination } from "./Pagination";
import styled from "styled-components";
import { TagsList } from "./shopTags";
import { CategoriesList } from "./shopCategories";

const ProductLink = styled(Link)`
  overflow: hidden;
  align-self: stretch;
`;

const ShopGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 200px;
  column-gap: 30px;
  row-gap: 30px;
  @media (max-width: 850px) {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
  }
  @media (max-width: 540px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
  }
`;

export const ShopLayout = ({ products, title, pageContext, page }) => {
  return (
    <Layout>
      <section className="section">
        <div className="container">
          <div className="content">
            {title && (
              <h3 className="title is-size-4 is-bold-light">{title}</h3>
            )}
            <ShopGrid>
              {products.map(props => (
                <ProductLink
                  to={props.slug}
                  key={props.id}
                >
                  <ProductItem {...props} />
                </ProductLink>
              ))}
              {page === "shop" ? <TagsList /> : <CategoriesList />}
            </ShopGrid>
          </div>
          <Pagination pageContext={pageContext} pageUrl={page} />
        </div>
      </section>
    </Layout>
  );
};
